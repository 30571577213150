import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { Breakpoints } from "../utils/responsive";
import { Navbar } from "./Navbar";
import { AppRoutes } from "../utils/routes";
import { useMemo } from "react";

const Container = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  padding: 16px;

  @media (max-width: ${Breakpoints.TABLET}) {
    flex-direction: column;
  }
`;

const Sidebar = styled.div`
  //   width: 300px;
  //   max-width: 300px;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${Breakpoints.TABLET}) {
    width: 100%;
    flex-direction: row;
    justify-content: center;
  }
`;

const MainContent = styled.div`
  flex: 1;
  min-height: calc(100vh - 32px);
  display: flex;
  flex-direction: column;
`;

const MainContentInner = styled.div`
  // padding: 16px;
  flex: 1;
`;

const Signature = styled.img`
  width: 150px;
  @media (max-width: ${Breakpoints.TABLET}) {
    margin-top: 0;
    max-width: 100px;
    width: 50%;
    flex: 1;
  }
`;

const MiniMe = styled.img`
  width: 300px;
  margin-top: 30px;

  @media (max-width: ${Breakpoints.TABLET}) {
    margin-top: 0;
    max-width: 150px;
    width: 50%;
    flex: 1;
  }
`;

const Footer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
  font-size: 12px;
`;

export const Layout = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();

  const mainImageUrl = useMemo<string>(() => {
    switch (location.pathname) {
      case AppRoutes.CONTACT:
        return "/minime-contact.png";
      case AppRoutes.ABOUT:
        return "/minime-about.png";
      case AppRoutes.HOME:
      default:
        return "/minime-default.png";
    }
  }, [location.pathname]);

  return (
    <Container>
      {/* <Sidebar>
        <MiniMe src={mainImageUrl} alt="It's me" />
        <Signature src="/spollo2.png" alt="Stephanie Pollo" />
      </Sidebar> */}
      <MainContent>
        {/* <Navbar /> */}
        <MainContentInner>{children}</MainContentInner>
        <Footer>
          <span>© {new Date().getFullYear()} Stephanie Pollo</span>
        </Footer>
      </MainContent>
    </Container>
  );
};
