import { useEffect, useState } from "react";
import { Breakpoints } from "../utils/responsive";
import { Media, Page } from "../utils/types";
import styled from "styled-components";
import { contentService } from "../utils/contentService";
import Lightbox from "yet-another-react-lightbox";
import { Spinner } from "../components/Spinner";
import { Skeleton } from "../components/Skeleton";

const Masonry = styled.div`
  display: flex;
  gap: 16px;
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: ${Breakpoints.TABLET}) {
    display: none;
  }
`;

const SingleColumn = styled.div`
  display: none;
  flex-direction: column;
  gap: 16px;

  @media (max-width: ${Breakpoints.TABLET}) {
    display: flex;
  }
`;

const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
  width: 100%;
`;

const TileImage = ({ media }: { media: Media }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <>
      {!isLoaded && (
        <Skeleton
          width={media.media_details.sizes.full.width}
          height={media.media_details.sizes.full.height}
        />
      )}
      <StyledImage
        src={media.media_details.sizes.full.source_url}
        alt={media.title.rendered}
        srcSet={`
      ${
        media.media_details.sizes.thumbnail
          ? `${media.media_details.sizes.thumbnail.source_url} 150w,`
          : ""
      }
      ${
        media.media_details.sizes.medium
          ? `${media.media_details.sizes.medium.source_url} 300w,`
          : ""
      }
      ${
        media.media_details.sizes.medium_large
          ? `${media.media_details.sizes.medium_large.source_url} 768w,`
          : ""
      }
      ${media.media_details.sizes.full.source_url} 800w
    `}
        onLoad={(e) => {
          if (e.currentTarget.complete) {
            setIsLoaded(true);
          }
        }}
        style={{
          display: isLoaded ? "block" : "none",
          opacity: isLoaded ? 1 : 0,
          transition: "opacity 0.3s ease-in-out",
        }}
      />
    </>
  );
};

export const HomePage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [gallery, setGallery] = useState<Media[]>([]);
  // const [lightboxImages, setLightboxImages] = useState<{ src: string }[]>([]);
  const [selectedLightboxImage, setSelectedLightboxImage] = useState<
    number | null
  >(null);
  const [about, setAbout] = useState<Page>();
  const [illustrations, setIllustrations] = useState<Page>();
  const oddImages = gallery.filter((_, index) => index % 2 === 0);
  const evenImages = gallery.filter((_, index) => index % 2 !== 0);

  useEffect(() => {
    setIsLoading(true);
    async function loadData() {
      const gallery = await contentService.getHomepageGallery();
      const about = await contentService.getAboutPageContent();
      const illustrations = await contentService.getIllustrationsPageContent();
      setGallery(gallery);
      setAbout(about);
      setIllustrations(illustrations);
      setIsLoading(false);
    }
    loadData();
  }, []);

  return (
    <PageWrapper>
      {isLoading && <Spinner />}
      {!isLoading && (
        <>
          <Header>
            <img
              src="/stephaniepollo.png"
              alt="Stephanie Pollo"
              className="ste"
            />{" "}
            <img
              src="/illustratorand.png"
              alt="Illustrator"
              className="illustrator"
            />
          </Header>
          <img src="/stetable.png" alt="Stephanie Pollo" />
          <Table>
            <CenteredElement>
              <div>
                <img
                  src="/about.png"
                  alt="About"
                  style={{ marginBottom: "30px", width: "340px" }}
                />
              </div>
            </CenteredElement>
            <AboutSection>
              <p dangerouslySetInnerHTML={{ __html: about?.content || "" }} />
            </AboutSection>
            <CenteredElement>
              <img
                src="/mail.png"
                alt="Mail"
                style={{ marginTop: "30px", marginBottom: "30px" }}
              />
            </CenteredElement>
            <ContactInfo>
              <img
                src="/contact.png"
                alt="Contact"
                style={{ height: "30px", marginTop: "-10px" }}
              />{" "}
              <span>steeerica@gmail.com | +48 669 338 103</span>
            </ContactInfo>
          </Table>
          <Portfolio>
            <PortfolioTextColumns>
              <div>
                <img
                  src="/illustrations.png"
                  alt="Illustrations"
                  style={{
                    width: "280px",
                    marginBottom: "30px",
                    marginTop: "-4px",
                  }}
                />
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: illustrations?.content || "",
                }}
              />
              <CenteredElement>
                <img
                  src="/ingredients.png"
                  alt="Ingredients"
                  style={{ marginTop: "20px" }}
                />
              </CenteredElement>
            </PortfolioTextColumns>
            <PortfolioImages>
              <Masonry>
                <Column>
                  {oddImages.map((image) => (
                    <button
                      key={image.id}
                      onClick={() =>
                        setSelectedLightboxImage(
                          gallery.findIndex((i) => i.id === image.id)
                        )
                      }
                    >
                      <TileImage media={image} />
                    </button>
                  ))}
                </Column>
                <Column>
                  {evenImages.map((image) => (
                    <button
                      key={image.id}
                      onClick={() =>
                        setSelectedLightboxImage(
                          gallery.findIndex((i) => i.id === image.id)
                        )
                      }
                    >
                      <TileImage media={image} />
                    </button>
                  ))}
                </Column>
                <SingleColumn>
                  {gallery.map((image, index) => (
                    <button
                      key={`single-${image.id}`}
                      onClick={() => setSelectedLightboxImage(index as number)}
                    >
                      <TileImage media={image} />
                    </button>
                  ))}
                </SingleColumn>
              </Masonry>
            </PortfolioImages>
          </Portfolio>
          <Footer>
            <FooterImage src="/wall.png" alt="Footer background" />
            <FooterInner>
              <FooterLink
                href="https://www.instagram.com/steeerica/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/instagram.png" alt="Instagram" />
              </FooterLink>
              <FooterLink
                href="https://www.linkedin.com/in/stephaniepollo/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/linkedin.png" alt="LinkedIn" />
              </FooterLink>
              <FooterLink
                href="https://medium.com/@stephaniepollo"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/medium.png" alt="Medium" />
              </FooterLink>
            </FooterInner>
          </Footer>
          <Lightbox
            open={selectedLightboxImage !== null}
            close={() => setSelectedLightboxImage(null)}
            slides={gallery.map((image) => ({
              src: image.media_details.sizes.full.source_url,
            }))}
            /* render={{
              buttonPrev: lightboxImages.length <= 1 ? () => null : undefined,
              buttonNext: lightboxImages.length <= 1 ? () => null : undefined,
            }} */
            index={selectedLightboxImage ?? 0}
          />
        </>
      )}
    </PageWrapper>
  );
};

const PageWrapper = styled.div``;

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${Breakpoints.TABLET}) {
    flex-direction: column;
    gap: 50px;
    margin-bottom: 20px;
  }
  .ste {
    @media (max-width: ${Breakpoints.TABLET}) {
      transform: scale(0.8);
    }
  }
  .illustrator {
    @media (max-width: ${Breakpoints.TABLET}) {
      transform: scale(0.7);
    }
  }
`;

const Table = styled.div`
  width: 100%;
  background-color: #71adb2;
  padding: 40px;
  color: #fff;
`;

const CenteredElement = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const AboutSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 600px;
  margin: 0 auto;
  text-align: justify;
`;

const Portfolio = styled.div`
  padding: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;

  @media (max-width: ${Breakpoints.TABLET}) {
    flex-direction: column;
    padding: 30px;
  }
`;

const PortfolioTextColumns = styled.div`
  flex: 0.3;
  text-align: justify;
  color: #797979;
  @media (max-width: ${Breakpoints.TABLET}) {
    flex: 1;
  }
`;

const PortfolioImages = styled.div`
  flex: 0.7;
  @media (max-width: ${Breakpoints.TABLET}) {
    flex: 1;
  }
  button {
    border: none;
    background: none;
    cursor: pointer;
    outline: none;
  }
`;

const Footer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
`;

const FooterInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  z-index: 1;
  @media (max-width: ${Breakpoints.TABLET}) {
    gap: 5px;
  }
`;

const FooterLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  img {
    @media (max-width: ${Breakpoints.TABLET}) {
      width: auto;
      height: 100px;
    }
  }
`;

const FooterImage = styled.img`
  width: 100%;
  height: auto;
  display: block;
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  max-width: 600px;
  margin: 0 auto;
`;
